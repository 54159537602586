<div class="row justify-content-center search-area mb-3">
  <div class="col-md-8 col-lg-6 text-center">
    <form #searchForm="ngForm" novalidate autocomplete="off">
      <div class="form-group">
        <input type="text"
               class="form-control"
               #dealerSearchTermInput
               [formControl]="dealerSearchTerm"
               placeholder="Search dealers"
               aria-label="Search dealers" />
      </div>
    </form>
  </div>
</div>

<div class="text-center">
  <p class="lead" *ngIf="searching">Searching...</p>
</div>

<div class="list-group list-group-striped">
  <div class="list-group-item pointable"
       *ngFor="let dealer of dealers$ | async" (click)="setSelectedDealer(dealer)">
    {{dealer.formattedLabel}}
  </div>
</div>
