import { computed, inject, Injectable, signal } from '@angular/core'
import { Observable, of } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AuthService } from '@appShared/services/auth.service'
import { DealerService } from '@appShared/services/dealer.service'
import { ISiteConfiguration } from '@appShared/interfaces/[CodeGen]/ISiteConfiguration'
import { environment } from '@appEnvironments/environment'

export enum LoadingStatus {
   loading = 'loading',
   success = 'success',
   error = 'error'
}

export interface AppState {
   siteConfig: ISiteConfiguration
   status: LoadingStatus
   error: string | null
}

@Injectable({
   providedIn: 'root'
})
export class AppFacade {
   private _commonService = inject(CommonService)
   private _authService = inject(AuthService)
   private _dealerService = inject(DealerService)

   private _appState = signal<AppState>({
      siteConfig: null,
      status: LoadingStatus.loading,
      error: null
   })

   //selectors
   siteConfig = computed(() => this._appState().siteConfig)
   currentProfile = computed(() => this.siteConfig().currentProfile)
   studentPortalLoginUri = computed(() => this.siteConfig().studentPortalLoginUri)
   salesPortalUri = computed(() => this.siteConfig().salesPortalUri)
   dealerTrainingUri = computed(() => this.siteConfig().dealerTrainingUri)
   clickUpUri = computed(() => this.siteConfig().clickUpUri)
   mediaResourceCenterUri = computed(() => this.siteConfig().mediaResourceCenterUri)
   teamHubUri = computed(() => this.siteConfig().teamHubUri)
   status = computed(() => this._appState().status)
   error = computed(() => this._appState().error)

   setSiteConfig(config: ISiteConfiguration): Observable<boolean> {
      if (config.siteUri) {
         environment.baseUri = config.siteUri
      }

      const siteConfig = {
         ...config,
         cacheBuster: (new Date()).getTime()
      }

      this._appState.update((state) => ({
         ...state,
         siteConfig,
         status: LoadingStatus.success,
      }))

      return of(true)
   }

   navigateToGen2(portalRoute?: string) {
      let portalUri = `${this.salesPortalUri()}context/selectDealer`
      let sourceId = this._dealerService.selectedDealer()?.contact.sourceId
      let sourceIdQueryString = sourceId
         ? `sourceId=${sourceId}`
         : ''
      let encodedPortalRoute = portalRoute
         ? `redirectUrl=${encodeURIComponent(portalRoute)}`
         : ''
      let portalQueryString = encodedPortalRoute
         ? sourceIdQueryString
            ? `?${sourceIdQueryString}&${encodedPortalRoute}`
            : `?${encodedPortalRoute}`
         : ''

      let uri = `${portalUri}${portalQueryString}`

      console.log('redirecting to Gen2:', uri)
      this._commonService.setLoading(true)

      window.location.replace(uri)
   }

   navigateToExternal(uri?: string, targetBlank?: boolean) {
      if (!uri) return

      console.log('redirecting to:', uri)

      if (targetBlank) {
         window.open(uri, '_blank')
         return
      }

      this._commonService.setLoading(true)
      window.location.replace(uri)
   }


}
