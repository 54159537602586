import { inject, Injectable } from '@angular/core'
import { MasterDataCacheService } from '@appShared/services/master-data-cache.service'
import { toSignal } from '@angular/core/rxjs-interop'

@Injectable({
   providedIn: 'root'
})
export class MasterDataCacheFacade {
   private _masterDataCacheService = inject(MasterDataCacheService)

   colleges = toSignal(this._masterDataCacheService.getColleges())
}
