import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DealerSearchComponent } from '@appShared/components/dealer-search/dealer-search.component';
import { ModalComponent } from '@appShared/components/modal/modal.component';

@Component({
  template: `
    <app-modal title="Dealer Search">
      <app-dealer-search></app-dealer-search>
    </app-modal>`,
   standalone: true,
   imports: [ModalComponent, DealerSearchComponent],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDealerSearchComponent {}
