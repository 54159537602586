import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable, filter, take, tap } from 'rxjs'
import { Store } from '@ngrx/store'
import { CommonService } from '@appShared/services/common.service'
import { DealerService } from '@appShared/services/dealer.service'
import { ISiteConfiguration } from '@appShared/interfaces/[CodeGen]/ISiteConfiguration'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthService {
   private _dealerService = inject(DealerService)
   private _httpClient = inject(HttpClient)
   private _authApi = '/auth';

   initializeSiteConfig(): Observable<ISiteConfiguration> {
      return this._httpClient.get<ISiteConfiguration>('api/config'/*, this._httpOptions*/).pipe(
         take(1),
         tap(siteConfig => {

            // override our environment
            if (siteConfig.siteUri) {
               environment.baseUri = siteConfig.siteUri
            }

            siteConfig = {
               ...siteConfig,
               cacheBuster: (new Date()).getTime(),
            } as ISiteConfiguration

            const profile = siteConfig?.currentProfile
            if (profile?.dealerInfo) {
               this._dealerService.setSelectedDealer(profile)
            }

            //this._store.dispatch(AppActions.loadSiteConfigSuccess({ siteConfig }))
         })
      )
   }

   getSiteConfig(): Observable<ISiteConfiguration> {
      return this._httpClient.get<ISiteConfiguration>('api/config').pipe(
         tap(siteConfig => this._dealerService.initializeDealerFromStorage(siteConfig?.currentProfile))
      )
   }
}
