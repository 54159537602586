<div class="modal-header" *ngIf="showHeader">
   <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
   <ng-content></ng-content>
</div>
<div class="modal-footer" *ngIf="showFooter">
   <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
   <button type="button" class="btn btn-site-primary" *ngIf="showSave" (click)="save()">Save changes</button>
</div>
