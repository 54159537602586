import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'

@Injectable({ providedIn: 'root' })
export class PublicOnlyLogoutGuardService {
   private _appFacade = inject(AppFacade)
   private _commonService = inject(CommonService)
   private _dealerService = inject(DealerService)

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const currentProfile = this._appFacade.currentProfile()

      this._dealerService.removeSelectedDealer()

      /* if logged in, log them out with a return */
      if (!!currentProfile) {
         const returnUrl = route['_routerState'].url
         this._commonService.logout(returnUrl)
         return of(false)
      }

      return of(true)
   }
}
