import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core'
import { TitleCasePipe } from '@angular/common'
import { provideRouter } from '@angular/router'
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule, MetaReducer } from '@ngrx/store'
import { APP_ROUTES } from '@app/app.routes'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import { NgxSpinnerModule } from "ngx-spinner"
import {
   AppConfigProvider,
   HttpRequestInterceptor,
   JQUERY_TOKEN,
   MOMENT_TOKEN,
   Toastr
} from '@appShared/services'
import { provideToastr } from 'ngx-toastr'
import { environment } from '@appEnvironments/environment'

/* eslint-disable @typescript-eslint/dot-notation */
const toastr: Toastr = window['toastr']
const moment = window['moment']
const jQuery = window['$']
const hybridWebView = window['HybridWebView']
/* eslint-enable @typescript-eslint/dot-notation */

/* extend the existing window interface to tell it about your new property. */
declare global {
   interface Window {
      sw_portal: any
   }

   interface Date {
      stdTimezoneOffset(): number

      isDstObserved(): boolean
   }
}

window.sw_portal = window.sw_portal || { api_history: [], errors: [] }

export const appConfig: ApplicationConfig = {
   providers: [
      provideAnimations(),
      provideToastr(),
      provideHttpClient(withInterceptorsFromDi()),
      importProvidersFrom(NgxSpinnerModule.forRoot(/*config*/)),
      provideRouter(APP_ROUTES),
      {
         provide: APP_INITIALIZER,
         useFactory: (appConfigProvider: AppConfigProvider) => {
            return () => appConfigProvider.init()
         },
         multi: true,
         deps: [AppConfigProvider]
      },
      {
         provide: Window,
         useValue: window
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpRequestInterceptor,
         multi: true
      },
      TitleCasePipe,
      //TODO - maybe "provide" these where they are actually used
      //{ provide: TOASTR_TOKEN, useValue: toastr },
      { provide: MOMENT_TOKEN, useValue: moment },
      { provide: JQUERY_TOKEN, useValue: jQuery },
      provideEnvironmentNgxMask()
   ]
}

Date.prototype.stdTimezoneOffset = function (): number {
   const jan = new Date(this.getFullYear(), 0, 1)
   const jul = new Date(this.getFullYear(), 6, 1)
   return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

Date.prototype.isDstObserved = function (): boolean {
   return this.getTimezoneOffset() < this.stdTimezoneOffset()
}
