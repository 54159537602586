import { Component, Injectable, Directive, TemplateRef, ChangeDetectionStrategy } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { NgClass } from '@angular/common'

/**
 * https://stackblitz.com/edit/angular-muowrf?file=package.json
 *
 * Options passed when opening a confirmation modal
 */
export interface ConfirmOptions {
   /**
    * The title of the confirmation modal
    */
   title: string

   /**
    * The message in the confirmation modal
    */
   message: string
   yesText?: string
   yesButtonClass?: string

   noText?: string
   noButtonClass?: string
   hideNo?: boolean

   reverseButtons?: boolean

   backdrop?: boolean | 'static'
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable({ providedIn: 'root' })
export class ConfirmState {
   /**
    * The last options passed ConfirmService.confirm()
    */
   options: ConfirmOptions

   /**
    * The last opened confirmation modal
    */
   modal: NgbModalRef

   /**
    * The template containing the confirmation modal component
    */
   template: TemplateRef<any>
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable({ providedIn: 'root' })
export class ConfirmService {
   constructor(private modalService: NgbModal, private state: ConfirmState) { }

   /**
    * Opens a confirmation modal
    * @param options the options for the modal (title and message)
    * @returns Promise<any> a promise that is fulfilled when the user chooses to confirm, and rejected when
    * the user chooses not to confirm, or closes the modal
    */
   confirm(options: ConfirmOptions): Promise<any> {
      this.state.options = {
         yesText: 'Yes',
         yesButtonClass: 'btn-danger',
         noText: 'No',
         noButtonClass: 'btn-secondary',
         ...options
      }
      this.state.modal = this.modalService.open(
         this.state.template,
         this.state.options
      )
      return this.state.modal.result
   }
}

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
   selector: 'app-confirm-modal-component',
   styles: [
      `
    .modal-footer.reverseButtons .yes { order: 1 }
  `
   ],
   template: `<div class="modal-header">
      <h4 class="modal-title">{{ options.title}}</h4>
    <button type="button" class="btn-close" (click)="dismiss()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div [innerHtml]="options.message"></div>
  </div>
  <div class="modal-footer" [ngClass]="{'reverseButtons': options.reverseButtons}">
    <button type="button" class="btn {{options.yesButtonClass}} yes" (click)="yes()">{{options.yesText}}</button>
    <button type="button" class="btn {{options.noButtonClass}}" (click)="no()" [hidden]="options.hideNo" data-bs-dismiss="modal">{{options.noText}}</button>
  </div>`,
   standalone: true,
   imports: [NgClass],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
   options: ConfirmOptions

   constructor(private state: ConfirmState) {
      this.options = state.options
      // console.log("component initialized")
   }

   yes(): void {
      this.state.modal.close('confirmed')
   }

   no(): void {
      this.state.modal.dismiss('not confirmed')
   }

   dismiss(): void {
      this.state.modal.dismiss()
   }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template app-confirm-simple>
 *   <app-confirm-modal-component></app-confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
   // eslint-disable-next-line @angular-eslint/directive-selector
   selector: '[app-confirm-simple]',
   standalone: true
})
export class ConfirmTemplateDirective {
   constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
      state.template = confirmTemplate
   }
}
