import { Injectable, Inject, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { CommonService } from '@appShared/services/common.service'
import { IAddress } from '@appShared/interfaces/[CodeGen]/IAddress'

@Injectable({
   providedIn: 'root'
})
export class ValidationService {
   private _httpClient = inject(HttpClient)
   private _commonService = inject(CommonService)
   private _httpOptions = this._commonService.httpOptions()
   private validationApi = '/api/validation'

   validateAddress(addressData: IAddress): Observable<any> {
      const options = this._httpOptions
      const url = this.validationApi + '/validateaddress'

      return this._httpClient
         .post<any>(url, addressData, options)
         .pipe(
            tap(data => this._commonService.logApi(url, data, addressData)),
            catchError(this._commonService.handleError<any>('validateAddress'))
         )
   }
}
