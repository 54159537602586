<div>

   <h1 class="text-center mt-4">App Links</h1>

   <div class="d-flex mx-auto justify-content-center">
      <div class="links mt-2 mt-md-4">
         <span (click)="goToExternal(external_uris.sales_rabbit_app.uri)" class="pointable">{{external_uris.sales_rabbit_app.title}}</span>
         <span (click)="goToPortal(gen2Routes.a4d_app)" class="pointable">{{gen2Routes.a4d_app.title}}</span>
         <span (click)="goToExternal(external_uris.e_goal_card.uri)" class="pointable">{{external_uris.e_goal_card.title}}</span>
         <span (click)="goToExternal(external_uris.adv_4_kids_app.uri)" class="pointable">{{external_uris.adv_4_kids_app.title}}</span>
         <span (click)="goToPortal(gen2Routes.sw_stats_app)" class="pointable">{{gen2Routes.sw_stats_app.title}}</span>
         <span (click)="goToExternal(external_uris.adv_4_teens_app.uri)" class="pointable">{{external_uris.adv_4_teens_app.title}}</span>
         <span (click)="goToPortal(gen2Routes.sw_capture_app)" class="pointable">{{gen2Routes.sw_capture_app.title}}</span>
         <span (click)="goToExternal(external_uris.skwids_app.uri)" class="pointable">{{external_uris.skwids_app.title}}</span>
         <span *ngIf="showClickUp()"
               (click)="goToExternal(clickUpUri())" class="pointable">{{external_uris.click_up.title}}</span>
         <span (click)="goToExternal(external_uris.lead_app.uri)" class="pointable">{{external_uris.lead_app.title}}</span>
         <!--<span (click)="goToExternal(external_uris.voxer.uri)" class="pointable">{{external_uris.voxer.title}}</span>-->
         <span (click)="goToExternal(external_uris.homer_app.uri)" class="pointable">{{external_uris.homer_app.title}}</span>
      </div>
   </div>

</div>
