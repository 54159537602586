<ng-container *ngTemplateOutlet="buttonConfig['text'] || buttonConfig['textChange'] ? text : image">
</ng-container>

<ng-template #text>
  <button type="{{buttonConfig.type}}"
          [ngClass]="buttonConfig.class"
          [ngStyle]="buttonConfig.styles"
          [disabled]="disabled"
          (click)="onTextBtnClick()">
    <svg class="button-spinner" viewBox="0 0 50 50"
         *ngIf="isLoading">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"> </circle>
    </svg><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
               *ngIf="buttonConfig.svgType === 'trash' && !isLoading">
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
    </svg><svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
               *ngIf="buttonConfig.svgType === 'add' && !isLoading">
      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
    </svg><svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-phone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
               *ngIf="buttonConfig.svgType === 'phone' && !isLoading">
      <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
    </svg> {{ buttonConfig.text }}
  </button>
</ng-template>

<ng-template #image>
  <img alt="image button"
       [src]="buttonConfig.src"
       [ngClass]="buttonConfig.class"
       [ngStyle]="buttonConfig.styles"
       (click)="onImgBtnClick()">
</ng-template>
