import { computed, DestroyRef, effect, inject, Injectable, signal } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { BehaviorSubject, Observable, Subject, catchError, lastValueFrom, of, switchMap, take, tap } from "rxjs"
import { CommonService } from "@appShared/services/common.service"
import { LocalStorageService } from '@appShared/services/local-storage.service'
import {
   IProfile,
   IDealerProfileInfo,
   IDealerEftAuthorizationInfo,
   INotification
} from "@appShared/interfaces/[CodeGen]/Interfaces"
import { RelevantCountry_ } from '@appShared/services/lookup/[CodeGen]/RelevantCountry'
import * as _ from 'lodash'
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop'

export enum EftRecordVerificationLevel_ {
   NotVerified = 0,
   RoutingNumber = 1,
   AccountNumber = 2
}

export enum NotificationsIncluded_ {
   Profile = 0,
   Payment,
   Confirmation
}

@Injectable({
   providedIn: "root"
})
export class DealerService {
   private _httpClient = inject(HttpClient)
   private _commonService = inject(CommonService)
   private _httpOptions = this._commonService.httpOptions()
   private _dealerApi: string = "/api/dealer/"

   /* selected dealer storage */
   private _localStorageService = inject(LocalStorageService)
   private _selectedDealerStorageKey = 'selectedDealer'
   private _selectedDealerSubject = new BehaviorSubject<IProfile>(null)
   selectedDealer$ = this._selectedDealerSubject.asObservable()
   selectedDealer = signal<IProfile | undefined>(undefined)
   canAccessSSRegistration = computed(() => {
      const selectedDealer = this.selectedDealer()
      return !!(selectedDealer?.dealerInfo)
               && !(selectedDealer.dealerInfo.isEurope1
                  || selectedDealer.dealerInfo.isInternationalB)
   })


   private _refreshNotifications = new Subject<number | undefined>()
   private refreshNotifications$ = this._refreshNotifications.asObservable()
   notifications = signal<INotification[]>([])

   constructor(destroyRef: DestroyRef) {
      this.refreshNotifications$.pipe(
         takeUntilDestroyed(destroyRef)
      ).subscribe({
         next: (dealerId) => {
            if (dealerId) this.getDealerNotifications(dealerId)
         }
      })

      effect(() => {
         const dealerId = this.selectedDealer()?.dealerInfo?.dealerId
         if (dealerId) this._refreshNotifications.next(dealerId)
      })
   }

   getDealer(dealerSalesSessionId: number): Observable<IProfile> {
      const url = `${this._dealerApi}${dealerSalesSessionId}`

      return this._httpClient.get<IProfile>(url)
   }

   getDealerManagerialScope(dealerSalesSessionId: number): Observable<any> {
      const url = `${this._dealerApi}${dealerSalesSessionId}/managerial-scope`

      return this._httpClient.get<any>(url)
   }

   setSelectedDealer(dealerProfile: IProfile): IProfile {
      if (dealerProfile) {
         this._localStorageService.saveData(this._selectedDealerStorageKey, JSON.stringify(dealerProfile))

         this.selectedDealer.set(dealerProfile)
      }

      return this.selectedDealer()
   }

   removeSelectedDealer() {
      this._localStorageService.removeData(this._selectedDealerStorageKey)

      this.selectedDealer.set(undefined)
   }

   initializeDealerFromStorage(loggedInProfile?: IProfile): void {
      const selectedDealerStorage = this._localStorageService.getData(this._selectedDealerStorageKey)
      const selectedDealer = selectedDealerStorage ? JSON.parse(selectedDealerStorage) as IProfile : null
      const loggedInProfileDealerInfo = loggedInProfile?.dealerInfo

      /* if logged in user is a dealer - set them as selected dealer */
      const profileToStore = loggedInProfileDealerInfo ? loggedInProfile : selectedDealer

      this.setSelectedDealer(profileToStore)
   }

   getDealerProfileInfo(
      contactSourceId: string
   ): Observable<IDealerProfileInfo> {
      const url = `${this._dealerApi}${contactSourceId}/profile-info`

      return this._httpClient.get<IDealerProfileInfo>(url)
   }

   async getDealerBankInfo(
      contactSourceId: string
   ): Promise<IDealerEftAuthorizationInfo> {
      const url = `${this._dealerApi}${contactSourceId}/field-eft-record-info`
      //TODO testing
      //return new Promise((resolve, reject) => {
      //   resolve({
      //      countryCode: 36,
      //      routingNumber: '012345678',
      //      accountNumber: '68381313',
      //      accountHolderName: 'Judy Jammer',
      //      bankName: 'First National of Canada'
      //   })
      //})

      const dealerBankInfo$ = this._httpClient
         .get<IDealerEftAuthorizationInfo>(url)
         .pipe(
            take(1),
            tap(data => this._commonService.logApi(url, data)),
            catchError(
               this._commonService.handleError<IDealerEftAuthorizationInfo>('getDealerBankInfo')
            ))

      return await lastValueFrom(dealerBankInfo$)
   }

   getDealerRemittances(
      contactSourceId: string,
      from: string,
      thru: string
   ): Observable<IDealerEftAuthorizationInfo[]> {
      const url = `${this._dealerApi}${contactSourceId}/field-eft-authorization-infos?from=${from}&thru=${thru}`


      return this._httpClient.get<IDealerEftAuthorizationInfo[]>(url)
   }

   async validateAbaRoutingNumber(routingNumber: string,
      relevantCountryCode?: RelevantCountry_): Promise<IDealerEftAuthorizationInfo> {
      relevantCountryCode = relevantCountryCode || RelevantCountry_.US
      const url = `${this._dealerApi}validate-aba-routing-number/${relevantCountryCode}/${routingNumber}`

      const request$ = this._httpClient.get<IDealerEftAuthorizationInfo>(url).pipe(take(1))

      return await lastValueFrom<IDealerEftAuthorizationInfo>(request$)
      /*
      Test Bank Numbers
      021000021, 011401533, 091000019: Valid US bank routing numbers. Use any 3-17 digit bank account number to go with it.
      00257 596, 00202 899, 00212 369: Valid Canadian bank transit and institution numbers. Use any 3-17 digit bank account number to go with them.

      REAL US: Ascend.org 264181626
      REAL CA: ROYAL BANK OF CANADA 000306177

      use:

      return this._dealerService.validateAbaRoutingNumber(routingNumber, relevantCountryCode).pipe(
         debounceTime(500),
         /distinctUntilChanged(),
         delay(500),
         map((isValid) => {
            return isValid
               ? of(null)
               : of({
                  invalidNumber: true
               })
         }
      ))
      */
   }

   async submitRemittance(
      dealerId: number,
      remittance: any
   ): Promise<any> {
      //if (!remittance) {
      //   return Promise.reject('Remittance not passed!')
      //}

      ////return Promise.resolve(remittance)
      //return Promise.reject('ISSUES: YOU ARE A LOSER!')

      const url = `${this._dealerApi}${dealerId}/submit-remittance`

      const request$ = this._httpClient.post<any>(url, remittance, this._httpOptions).pipe(take(1))

      return await lastValueFrom<any>(request$)

   }

   getDealerNotifications(dealerId: number, notificationType?: NotificationsIncluded_): void {
      const url = notificationType == null
         ? `${this._dealerApi}notifications/${dealerId}`
         : `${this._dealerApi}notifications/${dealerId}/${notificationType}`

      this._httpClient.get<INotification[]>(url).pipe(
         take(1)
      ).subscribe({
         next: (notifications) => this.notifications.set(notifications)
      })
   }

   refreshNotifications(): void {
      const dealerId = this.selectedDealer()?.dealerInfo?.dealerId
      if (dealerId) this._refreshNotifications.next(dealerId)
   }

   acknowledgeNotifications(notificationIds: number[]): Observable<any> {
      if (!notificationIds.length) return of(true)

      let idsQueryString = notificationIds.join('&notificationIds=')
      const url = `${this._dealerApi}notifications/acknowledge?notificationIds=${idsQueryString}`
         return this._httpClient
            .post<any>(url, {}, this._httpOptions)
   }

   sendPasswordResetVoucher(sourceId: string): Observable<any> {
      const url = `${this._dealerApi}send-password-reset-voucher/${sourceId}`
      return this._httpClient
         .post<any>(url, {}, this._httpOptions)
   }

   //submitRemittance(
   //   remittance: any
   //): Observable<any> {
   //   const options = this._commonService.httpOptions()
   //   const url = `${this.dealerApi}remittance`

   //   return of(remittance).pipe(
   //      tap(() => {
   //         throw new Error('Could not fetch user')
   //      })
   //   )
   //   /* mask ssn# for logging purposes */
   //   //const obfuscatedRegistrationForm = _.assignIn(_.clone(remittance), {
   //   //   ssn: '*************'
   //   //})

   //   //return this._httpClient
   //   //   .post<any>(url, remittance, options)
   //   //   .pipe(
   //   //      //tap(data => this._common.logApi(url, data, obfuscatedRegistrationForm)),
   //   //      catchError((err) => {
   //   //         this._common.handleError<ISalesSchoolRegistration>(
   //   //         'submitRegistration'
   //   //      )}
   //   //      )
   //   //   )
   //}
}
