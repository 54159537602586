import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core'
import { AsyncPipe, NgIf } from '@angular/common'
import { RouterLink } from '@angular/router'
import { toObservable } from '@angular/core/rxjs-interop'
import { of, switchMap } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { SITE_DIRECTORY_ROUTES, RouteModel } from '@appShared/site-directory.routes'
import { GEN2_ROUTES } from '@appShared/gen2.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.less'],
   standalone: true,
   imports: [NgIf, RouterLink, AsyncPipe],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
   private _routes = SITE_DIRECTORY_ROUTES
   salesSchoolRegistrationUri = this._routes.sales_school_registration.uri
   remitOnlineUri = this._routes.account.remittance.uri
   merchandiseReturnsUri = this._routes.merchandise_returns.uri
   manageAccountUri = this._routes.account.manage.uri

   private _appFacade = inject(AppFacade)
   currentProfile = this._appFacade.currentProfile
   dealerTrainingUri = this._appFacade.dealerTrainingUri
   mediaResourceUri = this._appFacade.mediaResourceCenterUri
   salesPortalUri = this._appFacade.salesPortalUri
   showClickUp = computed(() => {
      const profile = this.currentProfile()

      if (profile?.isAdmin || profile?.isSalesSupport || profile?.isManager) {
         return true
      }

      return profile?.dealerInfo?.isUSDealer
   })
   clickUpUri = this._appFacade.clickUpUri

   private _dealerService = inject(DealerService)
   selectedDealer = this._dealerService.selectedDealer
   canAccessSSRegistration = this._dealerService.canAccessSSRegistration
   canViewOrgReporting = toObservable(this._dealerService.selectedDealer).pipe(
      switchMap(dealer => {
         if (!dealer?.dealerInfo) {
            return of(false)
         }

         return this._dealerService.getDealerManagerialScope(dealer.dealerInfo.dealerSalesSessionId).pipe(
            switchMap(dealerScopes => {
               if (!dealerScopes) {
                  return of(false)
               }

               return of(!!(dealerScopes['isOrgLeader']) ||
                  !!(dealerScopes['isOrgAssist']) ||
                  !!(dealerScopes['isManager']) ||
                  !!(dealerScopes['isDSM']))
            })
         )
      })
   )

   gen2Routes = GEN2_ROUTES
   external_uris = EXTERNAL_URIS
   teamHubUri = computed(() => {
      const dealerInfo = this.currentProfile()?.dealerInfo
      const teamHubRoute = this.external_uris.team_hub


      if (dealerInfo) {
         return !dealerInfo.isHighlander
            ? dealerInfo.isUSDealer
               ? teamHubRoute.uri
               : teamHubRoute.alt_uri
            : null
      }

      return teamHubRoute.uri
   })

   /*
    public methods
   */

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }

   goToExternal(uri?: string): void {
      this._appFacade.navigateToExternal(uri)
   }
}
