export interface RouteModel {
   baseUri: string
   title: string
   uri: string
   alt_uri: string
}

export const SITE_DIRECTORY_ROUTES = {
   account: {
      manage: {
         title: 'Manage Account',
         baseUri: 'manage',
         uri: 'manage'
      } as RouteModel,
      remittance: {
         title: 'Remit Online',
         baseUri: 'remit-online',
         uri: 'remit-online'
      } as RouteModel,
      title: 'Account',
      baseUri: 'account',
      uri: 'account'
   },
   app_links: {
      title: 'App Links',
      baseUri: 'app-links',
      uri: 'app-links'
   },
   merchandise_returns: {
      title: 'Merchandise Returns',
      baseUri: 'merchandise-returns',
      uri: 'merchandise-returns'
   },
   forgot_password: {
      title: '',
      baseUri: 'forgot-password',
      uri: 'forgot-password'
   } as RouteModel,
   sales_school_registration: {
      title: 'Sales School Registration',
      baseUri: 'sales-school-registration',
      uri: 'sales-school-registration'
   },
   set_dealer: {
      title: '',
      baseUri: 'set-dealer',
      uri: 'set-dealer'
   } as RouteModel,
   title: 'Southwestern Advantage Dealer Portal',
   uri: ''
}
