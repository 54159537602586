import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Injectable({ providedIn: 'root' })
export class AccountGuardService {
   private _appFacade = inject(AppFacade)
   private _commonService = inject(CommonService)
   private _router = inject(Router)

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const currentProfile = this._appFacade.currentProfile()
      const dealerInfo = currentProfile?.dealerInfo
      const currentDssId = dealerInfo?.dealerSalesSessionId
      const canProceed =
         currentDssId || (currentProfile && currentProfile.canViewDealerSearch)

      if (!canProceed) {

         if (!(currentProfile?.userPrincipalId)) {
            const returnUrl = route['_routerState'].url
            this._commonService.navigateToLogin(returnUrl)
         } else {
            this._commonService.pageNotAuthorized()
            this._router.navigate([SITE_DIRECTORY_ROUTES.uri])
         }

         return of(false)
      }

      return of(true)
   }
}
