
<div class="reset-password mx-auto">

   <div class="border bg-white p-3 mb-3">

      <h4 class="mb-3">Password Reset</h4>

      <p>Enter you account number below to receive your password reset instructions via email.</p>

      <p>
         We will send a time-sensitive email with a link to reset your password.
         (if the reset link expires, you will be redirected back to this page.)
      </p>

      <p>
         New to Advantage4Dealers? register here<br />
         OR click here to login with your account number.
      </p>

      <em>*Do not attempt login with your email address</em>

   </div>

   <div class="border bg-white p-3 mb-3">

      <form [formGroup]="forgotPasswordForm"
            id="forgotPasswordForm"
            (ngSubmit)="sendPasswordResetVoucher()"
            autocomplete="off novalidate">

         <div class="d-flex align-items-center">

            <div class="form-group flex-grow-1"
                 [ngClass]="{'error': dealerSourceId.invalid && (dealerSourceId.dirty || dealerSourceId.touched || mouseoverResetSubmit())}">
               <input formControlName="dealerSourceId"
                      type="text"
                      maxlength="8"
                      name="dealerSourceId"
                      class="form-control text-center"
                      [readonly]="isSubmitting()"
                      required />
            </div>


            <span (mouseenter)="mouseoverResetSubmit.set(true)" (mouseleave)="mouseoverResetSubmit.set(false)"
                  class="ms-4">
               <app-button [buttonConfig]="{textChange: ['Submit'], class: 'btn btn-site-primary-training fw-bold fs-6 text-uppercase'}"
                           [isLoading]="isSubmitting()"
                           [disabled]="!forgotPasswordForm.valid || isSubmitting()"
                           (textBtnClickEmt)="sendPasswordResetVoucher()"></app-button>
            </span>

         </div>

      </form>

   </div>

   <div *ngIf="errorMessage()" class="alert alert-danger" role="alert">
      ERROR: {{errorMessage()}}
   </div>

   <div *ngIf="!errorMessage() && successfulEmailSent()" class="alert alert-success" role="alert">
      Password reset email has been sent to <span class="fw-bold">{{emailAddress()}}</span>
   </div>

</div>
<app-simple-modal title="Reset Password" >
   <p class="fw-bold">
      Password reset email has been sent to {{emailAddress()}}
   </p>
   <p>
      Check your email for further instructions.
   </p>
</app-simple-modal>
