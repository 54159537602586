import { Component, inject, signal, ViewChild } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { finalize, take } from 'rxjs'
import { DealerService } from '@appShared/services/dealer.service'
import { SimpleModalComponent } from '@appShared/components/simple-modal.component'
import { ButtonComponent } from '@appShared/components/button/button.component'
import { CommonService } from '@appShared/services/common.service'
import { ToastrType } from '@appShared/services/toastr.service'


@Component({
   selector: 'app-forgot-password',
   standalone: true,
   imports: [
      FormsModule, ReactiveFormsModule, NgClass, NgIf,
      ButtonComponent, SimpleModalComponent],
   templateUrl: './forgot-password.component.html',
   styleUrl: './forgot-password.component.less'
})
export class ForgotPasswordComponent {
   @ViewChild(SimpleModalComponent) successModal: SimpleModalComponent

   private _commonService = inject(CommonService)
   private _dealerService = inject(DealerService)

   /* form/fields */
   dealerSourceId = new FormControl(null, [
      Validators.required,
      Validators.maxLength(8),
      Validators.pattern(this._commonService.regexp().dealerAccount)])
   forgotPasswordForm = new FormGroup({
      dealerSourceId: this.dealerSourceId
   })
   mouseoverResetSubmit = signal(false)

   isSubmitting = signal(false)
   emailAddress = signal<string | undefined>(undefined)
   errorMessage = signal<string | undefined>(undefined)
   successfulEmailSent = signal<boolean>(false)

   sendPasswordResetVoucher() {
      if (this.forgotPasswordForm.valid) {
         this.isSubmitting.set(true)
         this.errorMessage.set(undefined)
         this.successfulEmailSent.set(false)

         const dealerSourceId = this.dealerSourceId.value

         this._dealerService.sendPasswordResetVoucher(dealerSourceId).pipe(
            take(1),
            finalize(() => this.isSubmitting.set(false))
         ).subscribe({
            next: voucherRequestResult => {
               if (!voucherRequestResult) {
                  this.errorMessage.set(`Unable to find account number '${dealerSourceId}'`)
                  return
               }

               console.log(voucherRequestResult)
               this.forgotPasswordForm.reset()
               this.forgotPasswordForm.clearValidators()

               this.successfulEmailSent.set(true)
               this.emailAddress.set(voucherRequestResult['emailAddress'])
               this.successModal?.openModal()

            },
            error: err => {
               this.errorMessage.set(err)
            },
            complete: () => this.isSubmitting.set(false)
         })
      }
   }
}
