import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { NgTemplateOutlet, NgClass, NgStyle, NgIf } from '@angular/common'
import * as _ from 'lodash'

export interface IButtonConfig {
   text: string
   textChange: string[]
   class: string
   imageUrl: string
   svg: any
}

@Component({
   selector: 'app-button',
   templateUrl: './button.component.html',
   standalone: true,
   imports: [NgTemplateOutlet, NgClass, NgStyle, NgIf],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnChanges {
   @Input() buttonConfig: any = {};
   @Input() isLoading: boolean
   @Input() disabled: boolean
   @Input() textChangeIndex: number
   @Output() textBtnClickEmt: EventEmitter<string> = new EventEmitter<string>();
   @Output() imgBtnClickEmt: EventEmitter<string> = new EventEmitter<string>();

   constructor() { }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['buttonConfig']?.currentValue) {

         //this.buttonConfig.svgType = this.buttonConfig.svgType || 'add';
         this.buttonConfig.type = this.buttonConfig.type || 'button'
         /* default button class if text button */
         if (this.buttonConfig.text || this.buttonConfig.textChange) {
            this.buttonConfig.class = `text-nowrap ${(this.buttonConfig.class || 'btn btn-site-primary')}`

            if (_.isArray(this.buttonConfig.textChange)
               && this.buttonConfig.textChange.length) {
               this.textChangeIndex = this.textChangeIndex || 0
               this.buttonConfig.text = this.buttonConfig.textChange[this.textChangeIndex]
            }

            if (!this.buttonConfig.text) {
               this.buttonConfig.text = 'Submit'
            }
         }
      } else if (this.buttonConfig.textChange
         && changes?.['textChangeIndex']
         && [0, 1].includes(changes['textChangeIndex'].currentValue)) {
         this.buttonConfig.text = this.buttonConfig.textChange[this.textChangeIndex]
      }
   }

   /*
    * public methods
    * */

   onTextBtnClick(): void {
      this.textBtnClickEmt.emit('You have clicked on a text button.')
   }

   onImgBtnClick(): void {
      this.imgBtnClickEmt.emit('You have clicked on an image button.')
   }
}

/* USE:
https://medium.com/better-programming/how-to-create-a-reusable-button-component-in-angular-42893f521834

<app-button [buttonConfig]="textBtnConfig"
            (textBtnClickEmt)="onClickEventReceived($event)"></app-button>
<app-button [buttonConfig]="imgBtnConfig"
            (imgBtnClickEmt)="onClickEventReceived($event)"></app-button>

textBtnConfig = {
    styles: {
      position: 'relative',
      width: '150px',
      height: '60px',
      backgroundColor: '#f92672',
      color: '#fff',
      fontFamily: 'sans-serif',
      fontSize: '20px',
      borderRadius: '10px',
      marginTop: '30px'
    },
    text: 'Click Here'
  };

  imgBtnConfig = {
    styles: {
      position: 'relative',
      width: '100px',
      height: '100px'
    },
    src: './assets/conversation.png'
  };

*/
