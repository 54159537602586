import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const currentProfile = this._appFacade.currentProfile()

      if (!(currentProfile?.userPrincipalId)) {
         const returnUrl = this._commonService.getResolvedUrl(route)
         this._commonService.navigateToLogin(returnUrl)
         return of(false)
      }

      return of(true)
   }
}
