import { ChangeDetectionStrategy, Component, computed, inject, TrackByFunction } from '@angular/core'
import { NgFor, NgIf } from '@angular/common'
import { Router, Event, NavigationEnd, RouterLink } from '@angular/router'
import { filter, take } from 'rxjs/operators'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDealerSearchComponent } from '@appShared/components/dealer-search/modal-dealer-search.component'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { CommonService } from '@appShared/services/common.service'
import { INotification } from '@appShared/interfaces/[CodeGen]/INotification'
import { NotificationLevel_ } from '@appShared/services/lookup/[CodeGen]/NotificationLevel'
import { DealerNotificationType_ } from '@appShared/services/lookup/[CodeGen]/DealerNotificationType'
import { SITE_DIRECTORY_ROUTES, RouteModel } from '@appShared/site-directory.routes'
import { GEN2_ROUTES } from '@appShared/gen2.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'

@Component({
   selector: 'app-nav-menu',
   templateUrl: './nav-menu.component.html',
   styleUrls: ['./nav-menu.component.less'],
   standalone: true,
   imports: [NgIf, NgFor, RouterLink],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMenuComponent {
   private _commonService = inject(CommonService)
   isAuthenticated = false
   isExpanded = false

   private _routes = SITE_DIRECTORY_ROUTES
   salesSchoolRegistrationRoute = this._routes.sales_school_registration
   remitOnlineRoute = this._routes.account.remittance
   manageAccountRoute = this._routes.account.manage
   gen2Routes = GEN2_ROUTES
   external_uris = EXTERNAL_URIS

   private _appFacade = inject(AppFacade)
   currentProfile = this._appFacade.currentProfile
   salesPortalUri = this._appFacade.salesPortalUri
   clickUpUri = this._appFacade.clickUpUri
   showClickUp = computed(() => {
      const profile = this.currentProfile()

      if (profile?.isAdmin || profile?.isSalesSupport || profile?.isManager) {
         return true
      }

      return profile?.dealerInfo?.isUSDealer
   })

   private _dealerService = inject(DealerService)
   selectedDealer = this._dealerService.selectedDealer

   private _notifications = this._dealerService.notifications
   filteredNotifications = computed(() => {
      /* show no more than 5 of "un"-cleared notifications*/
      let notifications = this._notifications().filter(notification => !notification.isCleared).slice(0, 5)
      /* set alert level css */
      notifications.forEach(notification => {
         switch (notification.level) {
            case NotificationLevel_.Informational:
               notification['css'] = 'alert-info'
               break
            case NotificationLevel_.Important:
               notification['css'] = 'alert-danger'
               break
            case NotificationLevel_.Urgent:
               notification['css'] = 'alert-warning'
               break
            default:
               break
         }
      })
      return notifications
   })
   notificationCountDisplay = computed(() => {
      const filteredCount = this._notifications().filter(notification => !notification.isCleared).length
      return filteredCount > 5 ? '5+' : '' + filteredCount
   })
   showNotificationCount = computed(() => this.filteredNotifications().length > 0)
   notificationLevel_ = NotificationLevel_

   constructor(
      private _modalService: NgbModal,
      router: Router
   ) {
      router.events
         .pipe(
            takeUntilDestroyed(),
            filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
         )
         .subscribe((routerEvent: NavigationEnd) => {
            const url = routerEvent.url.toLowerCase()
            this.isAuthenticated = !url.startsWith('/student-dealer')
         })
   }

   goToExternal(uri?: string): void {
      this._appFacade.navigateToExternal(uri)
   }

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }

   collapse() {
      this.isExpanded = false
   }

   toggle() {
      this.isExpanded = !this.isExpanded
   }


   searchDealers() {
      const editLeadModalRef = this._modalService.open(
         ModalDealerSearchComponent,
         { scrollable: true }
      )
   }

   logout(): void {
      this._commonService.logout();
   }

   trackByNotificationId: TrackByFunction<INotification> = (index, notification) => notification.id

   acknowledgeNotification(notification: INotification): void {

      this._dealerService.acknowledgeNotifications([notification.id])
         .pipe(take(1))
            .subscribe(_ => {
               this._resolveNotificationAction(notification)
         })
   }

   seeAllNotifications() {
      this.goToPortal(this.gen2Routes.settings_notifications)
   }

   clearAllNotifications() {
      const notificationIds = this.filteredNotifications().map(n => n.id)

      if (notificationIds.length)
         this._dealerService.acknowledgeNotifications(notificationIds)
            .pipe(take(1))
            .subscribe(_ => {
               this._dealerService.refreshNotifications()
            })
   }

   private _resolveNotificationAction(notification: INotification): void {
      if (!!!notification) return


      const notificationType: DealerNotificationType_ = notification.type

      if ([DealerNotificationType_.CustomerServiceContact,
         DealerNotificationType_.CustomerServiceCancel,
         DealerNotificationType_.CustomerServicePendingRefund,
         DealerNotificationType_.ScheduledPaymentDecline,
         DealerNotificationType_.PaymentCardMaintenanceCancel].includes(notificationType)) {

         const route = `${this.gen2Routes.record_of_sale_customer.uri}/${notification.key}`
         this._appFacade.navigateToGen2(route)
         return
      }

      if (notificationType === DealerNotificationType_.MissingStats) {
         this.goToPortal(this.gen2Routes.e_bar)
         return
      }

      this._dealerService.refreshNotifications()
   }
}
