import { Injectable } from '@angular/core';
import {
  Lookups,
  RelevantStateProvince_,
  RelevantStateProvince,
  RelevantStateProvinces,
  Gender,
  Genders,
  Gender_
} from './[CodeGen]/DomainModel';
import { IDomainModel } from './IDomainModel';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LookupService {
  private _lookups: IDomainModel[][];
  static instance: LookupService;

  constructor() {
    this._lookups = Lookups;
    LookupService.instance = this;
  }

  toDomainModel<T extends IDomainModel, C extends number>(
    t: new () => T,
    code: C
  ): T {
    let proto: T = new t();
    let domainModels = this._lookups.find(
      dm => dm[0].typeName === proto.constructor.name
    );

    let domainModel = domainModels.find(l => l.code === code);

    return <T>domainModel;
  }

  toDomainModels<T extends IDomainModel>(t: new () => T): T[] {
    let proto: T = new t();
    let domainModels = this._lookups.find(
      dm => dm[0].typeName === proto.constructor.name
    );

    return <T[]>domainModels;
  }

  // for use in BillingAddress class where services are not Injectable
  public static getStateEntity(
    stateProvinceCode: RelevantStateProvince_
  ): RelevantStateProvince {
    if (stateProvinceCode) {
      // this is not working for some reason - investigate later
      //return this.instance.toDomainModel(RelevantStateProvince, stateProvinceCode);
      let states = RelevantStateProvinces;
      return _.find(states, ['code', stateProvinceCode]);
    }

    return null;
  }

  public static getStates(): RelevantStateProvince[] {
    // remove Canadian RelevantStateProvinces
    let canadianStateCodes: RelevantStateProvince_[] = [
      RelevantStateProvince_.AB_CA,
      RelevantStateProvince_.BC_CA,
      RelevantStateProvince_.MB_CA,
      RelevantStateProvince_.NB_CA,
      RelevantStateProvince_.NL_CA,
      RelevantStateProvince_.NS_CA,
      RelevantStateProvince_.NT_CA,
      RelevantStateProvince_.NU_CA,
      RelevantStateProvince_.ON_CA,
      RelevantStateProvince_.PE_CA
    ];
    // this is not working for some reason - investigate later
    //let states = this.instance.toDomainModels(RelevantStateProvince);
    let states = RelevantStateProvinces;
    return states.filter(state => !_.includes(canadianStateCodes, state.code));
  }

  public static getGenders(): Gender[] {
    return Genders.filter(gender => gender.code != Gender_.NotSpecified);
  }
}
