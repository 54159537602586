import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { NgIf } from '@angular/common'

@Component({
   selector: 'app-modal',
   templateUrl: './modal.component.html',
   styles: [
      `.modal-header {
        background-color: #ed8322;
        color: #FFF;
    }
    .modal-body {
      background-color: #FAFAFA;
    }`
   ],
   /* eslint-disable @angular-eslint/no-host-metadata-property */
   host: {
      '[style.display]': '"flex"',
      '[style.flex-direction]': '"column"',
      '[style.overflow]': '"hidden"'
   }
   /* eslint-enable @angular-eslint/no-host-metadata-property */
   ,
   standalone: true,
   imports: [NgIf],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {
   @Input() title = ``;
   @Input() showSave = false;
   @Input() showHeader = true;
   @Input() showFooter = true;
   @Input() closeFunction: () => void | null
   @Input() dismissFunction: () => void | null
   @Input() saveFunction: () => void | null

   constructor(public activeModal: NgbActiveModal) { }

   /*
    * public methods
    * */

   dismiss(): void {
      this.activeModal.dismiss(this.closeFunction)
   }

   close(): void {
      this.activeModal.close(this.dismissFunction)
   }

   save(): void {
      this.activeModal.close(this.saveFunction)
   }
}
