import { Component, computed, inject } from '@angular/core'
import { NgIf } from '@angular/common'
import { AppFacade } from '@appShared/services/app.facade'
import { RouteModel } from '@appShared/site-directory.routes'
import { GEN2_ROUTES } from '@appShared/gen2.routes'
import { EXTERNAL_URIS } from '@appShared/external.uris'

@Component({
  selector: 'app-app-links',
  standalone: true,
  imports: [NgIf],
  templateUrl: './app-links.component.html',
  styleUrls: ['./app-links.component.less']
})
export class AppLinksComponent {
   private _appFacade = inject(AppFacade)
   showClickUp = computed(() => {
      const profile = this._appFacade.currentProfile()

      if (profile?.isAdmin || profile?.isSalesSupport || profile?.isManager) {
         return true
      }

      return profile?.dealerInfo?.isUSDealer
   })
   clickUpUri = this._appFacade.clickUpUri
   gen2Routes = GEN2_ROUTES
   external_uris = EXTERNAL_URIS

   goToExternal(uri?: string): void {
      this._appFacade.navigateToExternal(uri)
   }

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }
}
