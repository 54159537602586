
<div class="dashboard h-90 d-flex flex-column justify-content-center">

   <div>
      <div class="modules">

         <div class="module pointable">
            <span (click)="goToPortal(gen2Routes.record_of_sale)" class="pointable">
               <img src="/assets/images/dashboard/record-of-sales.png" class="w-100" />
            </span>
         </div>

         <div class="module pointable">
            <a routerLink="/{{manageAccountUri}}">
               <img src="/assets/images/dashboard/manage-account.png" class="w-100" />
            </a>
         </div>

         <div class="module pointable">
            <span (click)="goToPortal(gen2Routes.activity_reports)" class="pointable">
               <img src="/assets/images/dashboard/activity-reports.png" class="w-100" />
            </span>
         </div>

         <div class="module pointable">
            <span (click)="goToPortal(gen2Routes.e_bar)" class="pointable">
               <img src="/assets/images/dashboard/e-bar.png" class="w-100" />
            </span>
         </div>

         <div class="module pointable">
            <span (click)="goToExternal(mediaResourceUri())" class="pointable">
               <img src="/assets/images/dashboard/media-resource-center.png" class="w-100" />
            </span>
         </div>

         <div class="module pointable">
            <span (click)="goToExternal(dealerTrainingUri())" class="pointable">
               <img src="/assets/images/dashboard/training-courses.png" class="w-100" />
            </span>
         </div>

         <div *ngIf="teamHubUri()"
              class="module pointable">
            <span (click)="goToExternal(teamHubUri())" class="pointable">
               <img src="/assets/images/dashboard/team-hub.png" class="w-100" />
            </span>
         </div>

         <div *ngIf="showClickUp()"
              class="module pointable">
            <span (click)="goToExternal(clickUpUri())">
               <img src="/assets/images/dashboard/click-up.png" class="w-100" />
            </span>
         </div>

      </div>

      <!--hiding this for now-->
      <!--<div *ngIf="canViewOrgReporting|async" class="d-flex justify-content-center">
         <span (click)="goToPortal(gen2Routes.org_reporting)" class="pointable">
            <img src="/assets/images/dashboard/org-reporting_button.png" class="org-reporting" />
         </span>
      </div>-->
   </div>

</div>
