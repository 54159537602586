import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterLink } from '@angular/router'

@Component({
   selector: 'app-site-footer',
   templateUrl: './site-footer.component.html',
   styleUrls: ['./site-footer.component.less'],
   standalone: true,
   imports: [RouterLink],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class SiteFooterComponent {
   currentYear = (new Date()).getFullYear()
}
