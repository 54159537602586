import { Component, inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { catchError, EMPTY, take, tap } from 'rxjs'
import { DealerService } from '@appShared/services/dealer.service'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

/*
   This component's sole purpose is to act as a proxy between
   this "portal" site and "Gen2 Advantage4Dealers" website
   to keep the "selected dealer" in sync as they bounce
   between the 2 sites - this will set the selected dealer and
   then redirect to requested url if supplied

   external use: [portalSiteUri]/set-dealer?dssId=68059&url=%2Fsales-school-registration
*/
@Component({
  selector: 'app-set-dealer',
  standalone: true,
  imports: [CommonModule],
  template: ''
})
export class SetDealerComponent implements OnInit {
   private _activatedRoute = inject(ActivatedRoute)
   private _router = inject(Router)
   private _dealerService = inject(DealerService)

   ngOnInit(): void {
      const paramMap = this._activatedRoute.snapshot.queryParamMap
      const dealerSalesSessionId = paramMap.get('dssId')
      const redirectUrl = paramMap.get('url') || SITE_DIRECTORY_ROUTES.uri

      if (dealerSalesSessionId) {
         this._dealerService
            .getDealer(+dealerSalesSessionId)
            .pipe(
               take(1),
               tap(dealerProfile => this._dealerService.setSelectedDealer(dealerProfile)),
               catchError(err => {
                  console.error(`Error Occurred: ${err}`)
                  this._router.navigate([redirectUrl])
                  return EMPTY
               })
            )
            .subscribe(resp => {
               this._router.navigate([redirectUrl])
            })
      } else {
         this._router.navigate([redirectUrl])
      }
   }
}
