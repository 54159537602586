import { Component, Input, AfterViewInit, OnDestroy, inject, ViewChild, ElementRef } from '@angular/core'
import { NgIf } from '@angular/common'
import { CommonService } from '@appShared/services/common.service'
import { WindowService } from '@appShared/services/window.service'


@Component({
   // eslint-disable-next-line @angular-eslint/component-selector
   selector: 'app-simple-modal',
   template: `
   <div #simpleModal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-scrollable">
         <div class="modal-content">
            <div class="modal-header">
               <h1 class="modal-title fs-5" id="staticBackdropLabel">{{title}}</h1>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <ng-content></ng-content>
            </div>
            <div class="modal-footer">
             <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
             <button type="button" class="btn btn-primary" *ngIf="showSave">{{saveButtonTitle}}</button>
            </div>
         </div>
      </div>
   </div>
  `,
   standalone: true,
   imports: [NgIf]
})
export class SimpleModalComponent implements AfterViewInit, OnDestroy {
   @ViewChild('simpleModal') modal?: ElementRef
   @Input() title? = ''
   @Input() iFrameId?: string | number = ''
   @Input() showSave = false
   @Input() closeFunction = (): void => { }
   @Input() saveButtonTitle = 'Save changes'

   private _commonService = inject(CommonService)
   // @ts-ignore
   private $: any = inject(WindowService).nativeWindow['$'] as any

   ngAfterViewInit(): void {
      // if they give an elementId and pass a closeFunction
      // then attach to modal close event
      //if (this.closeFunction) {
      //   const self = this
      //   this.$(this.modal?.nativeElement).on('hidden.bs.modal', (e) => {
      //      self._commonService.execute(self.closeFunction)
      //   })
      //}
   }

   ngOnDestroy(): void {
      this.$(this.modal?.nativeElement).modal('dispose')
   }

   openModal(): void {
      this.$(this.modal?.nativeElement).modal('show')
   }

   closeModal(): void {
      this.$(this.modal?.nativeElement).modal('hide')
   }
}
