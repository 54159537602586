import { RouteModel } from './site-directory.routes'

export const EXTERNAL_URIS = {
   adp_workforce: {
      title: 'ADP Workforce',
      uri: 'https://workforcenow.adp.com/&callingAppId=WFN'
   } as RouteModel,
   adv_4_kids_app: {
      title: 'Advantage4Kids',
      uri: 'https://apps.apple.com/us/app/advantage4kids/id1455897437'
   } as RouteModel,
   adv_4_teens_app: {
      title: 'Advantage4Teens',
      uri: 'https://apps.apple.com/us/app/swadvantage/id990913888'
   } as RouteModel,
   click_up: {
      title: 'Click-Up',
      uri: 'https://app.clickup.com/9011031828/home'
   } as RouteModel,
   e_goal_card: {
      title: 'E-Goal Card',
      uri: 'https://goalcard.southwestern.ee/'
   } as RouteModel,
   homer_app: {
      title: 'Homer',
      uri: 'https://apps.apple.com/us/app/homer-fun-learning-for-kids/id601437586'
   } as RouteModel,
   it_pr_support: {
      title: 'IT/PR Support',
      uri: 'https://docs.google.com/document/d/1LTse7F6jI8Bydwo5KKyjvZia7WbAHBlSs81OavWbA44'
   } as RouteModel,
   lead_app: {
      title: 'LEAD',
      uri: 'https://apps.apple.com/us/app/southwestern-lead/id6478800540'
   } as RouteModel,
   ol_setup_resources: {
      title: 'OL Setup Resources',
      uri: 'https://www.southwesternadvantageforce.com/orgleader'
   } as RouteModel,
   registration_document_upload: {
      title: 'Upload Documents',
      uri: 'https://tinyurl.com/DealerDocumentForm'
   } as RouteModel,
   sales_rabbit_app: {
      title: 'Sales Rabbit',
      uri: 'https://apps.apple.com/us/app/salesrabbit/id879884387'
   } as RouteModel,
   skwids_app: {
      title: 'Skwids',
      uri: ' https://apps.apple.com/us/app/skwids-hd/id517195299'
   } as RouteModel,
   stock_summary: {
      title: 'Stock Summary',
      uri: 'https://stock.southwestern.com'
   } as RouteModel,
   team_hub: {
      title: 'Click-Up',
      uri: 'https://www.southwesternadvantageforce.com/',
      alt_uri: 'https://swaleaders.eu/'
   } as RouteModel,
   telegram: {
      title: '?? Telegram',
      uri: '/*TODO*/'
   } as RouteModel,
   voxer: {
      title: '?? Voxer',
      uri: '/*TODO*/'
   } as RouteModel
}
